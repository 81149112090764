@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
}
body{
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
}
