.head_container {
    background-color: #222222;
    /* height: 100%; */
    position: sticky;
    top: 0;
    z-index: 100;
}

.common_container {
    padding-inline: 6rem;

}

.header__div {
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.heder_space {
    display: flex;
    justify-content: space-between;
    text-align: center;
}

.logo {
    margin-top: 12px;
    width: auto;
    position: sticky;
    margin-bottom: 12px;
    height: 50px;
}

.showMenu_btn {
    display: none;
    color: red;
}

.heading_detail {
    display: flex;
    justify-content: space-between;

}

.heading {
    font-size: large;
    text-decoration: none;
    color: #FFFFFF !important;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    cursor: pointer;

}

.download_button {
    height: 35px;
    width: 80px;
    display: flex;
    gap: 1rem;
}

.heading:hover {
    font-weight: 600;
    transition: all 0.5s ease-in-out !important;
}

.heading_active {
    color: #FFFFFF;
    font-size: small;
    text-decoration: none;
    color: #262626;
    font-weight: 600;
}

.background_img {
    height: 100vh;
    background-image: url('../assests/backgroundimg.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}

.swap_text {
    color: #fff;
    text-align: center;
    width: 100%;

}

.try_button {
    background-color: #FFCA00 !important;
    font-size: 1.rem;
    height: 3.5rem;
    width: 14rem;
    color: #222222 !important;
    font-weight: 600;
    margin-top: 12px;
}

.editor {
    color: #1E1E1E;
    background-color: #ffffffce;
    border-radius: 50px;
    height: 40px;
    padding: 0.6rem 1.2rem;
    font-size: 1.5rem;

}

.home_heading {
    font-size: 3.5rem;
    font-weight: 500;
    color: #FFFFFF;
    margin-top: 16px;
    margin-bottom: 16px;

}

.paragraph {
    font-size: 1rem;
    margin-top: 12px;
    color: #FFFFFF;
}

.center_text {
    /* width: 50%; */
    margin: 16px;
}

.features_section {
    text-align: center;
    /* margin-top: 16px; */

}

.title {
    font-size: 2.5rem;
    color: #FFFFFF;
    font-weight: 700;
}

.awesome_feature {
    background-image: url('../assests/featureback.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #000000;
    padding: 3rem 6rem;
    text-align: center;
}

.features_image {
    width: 50%;

}

.awesome_MainDiv {
    text-align: center;
}

.contact_button {
    background-color: #FFCA00 !important;
    height: 4rem;
    width: 14rem;
    color: #000000 !important;
    font-weight: 600;
    margin-top: 20px;
    text-align: center;
    background-repeat: no-repeat;
}

.aboutus_background {
    background-position: center;
    background-color: #161616;
    padding: 3rem 6rem;
    background-image: url('../assests/featureback.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}



.leftside_about {
    text-align: justify;

}

.playestore_button {
    justify-content: center;
    gap: 1rem;
    display: flex;
    margin: 48px 0px;
}

.mobile_image {
    width: 100%;
}

.download_container {
    background-position: center;
    background-color: #161616;
    padding: 3rem 6rem;
    background-image: url('../assests/featureback.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.form_style {
    background-color: transparent;
    width: 80%;
    border: 1px solid white;
    padding: 10px;
    color: #FFFFFF;
}

.messageform_style {
    background-color: transparent;
    width: 80%;
    border: 1px solid white;
    padding-bottom: 50px;
    color: #FFFFFF;
}

.form_style::placeholder {
    color: #FFFFFF;
}

.messageform_style::placeholder {
    color: #FFFFFF;
}

.modal_form {
    padding: 40px opx;
}

.footer_parent {
    background-image: url('../assests/featureback.png');
    background-position: center;
    background-size: cover;
    height: 100%;
    display: flex;
    justify-content: end;
    flex-direction: column;
    width: 100%;
    background-color: #161616;
    padding: 3rem 0;
}

.footer_logo {
    text-align: center;
    margin-bottom: 15px;
}

.footer_detail {
    display: flex;
    justify-content: space-around;
    color: #000 !important;
    margin-inline: auto;
    margin-top: 33px;
    gap: 5rem;
    align-items: center;
}

.bottom {
    font-size: 1rem;
    padding: 8px;
    background-color: #26333B;
    text-align: center;
    color: #fff;
}

.contact_girl {
    text-align: end;
   
}
.avatargirl{
    width: 70%;
}

/* video */
.play_button {
    position: absolute;
    text-align: center;
    top: 45%;
    width: 100%;
}

.play_logo{
    width: 70px;
    height: 70px;

}

.showMenu_btn {
    display: none;
}

.download_butt {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.playstore_button{
    width: 20%;
}

@media screen and (max-width:992px) {
    .heading_detail {
        display: none !important;
    }
.color_change a{

    color: #000 !important;
}
   

    .messageform_style {
        width: 100%;
    }

    .aboutPadding {
        padding: 0rem 0px 1rem 0;
    }


    .common_container {

        padding-inline: 2rem;
    }

    .download_button {
        display: none;
    }

    .showMenu_btn {
        display: flex;
        align-items: center;
        color: red;
    }

    .leftside_about {
        margin-top: 0px;
        margin-bottom: 1rem;
    }

    .playestore_button {
        flex-direction: column;

    }

    .footer_detail {
        flex-direction: column;
        gap: 0rem;
    }

    .heading {
        font-size: medium;
    }

    .form_style {
        width: 100%;
    }
}
@media screen and (min-width: 992px) and (max-width: 1220px) {
    .heading{
        font-size: 1rem;
    }
   
    }

@media screen and (min-width: 320px) and (max-width: 768px) {

    .editor {
        font-size: .9rem;
    }
      
    .home_heading{
        font-size: 2rem;
    }
    .title{
        font-size: 2rem;
    }
    .awesome_feature{
        padding: 2rem;
    }
    .swap_text{
        padding: 2rem;
    }
    .leftside_about{
        margin-top: 40px;
    }
    .aboutus_background{
        padding: 2rem;
    }
    .playestore_button{
        margin: auto;
    }
    .awesome_feature{
        text-align: left;
    }
    .download_container{
        padding: 2rem;
    }
    .avatargirl{
        width: 100%;
    }
    .playstore_button{
        width: 140px;
        height: 40px;
    }
 
}
@media screen and (min-width: 200px) and (max-width: 425px) {
    .play_button{
        top: 25%;
    }
}